import { useState, useEffect } from "react"
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import { useForm } from "react-hook-form";
import { Button, CustomForm } from "./style"
import './style.css';
import { toast } from "react-toastify";
import { clientService } from "../../services";
import TokenService from "../../services/token.service";
import { InfoText } from "./info";
import {
    GetCountries,
    GetState,
} from "react-country-state-city";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PasswordValidation from "./passwordValidation";
import loader from "../../template/images/loader.gif";

import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const Form = ({ entity }) => {
    const [refValue, setRefValue] = useState('69');
    const [ptrValue, setPtrValue] = useState('');
    const [userLogin, setUserLogin] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [countryid, setCountryid] = useState(0);
    const [stateid, setStateid] = useState(0);
    const [declaration, setDeclaration] = useState(false);
    const [check, setCheck] = useState(false);
    const [cityid, setCityid] = useState(0);
    const [emailError, setEmailError] = useState(0);
    const [imageList, setImageList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [loading, setLoading] = useState(false);

    const [countryList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [rec, setRec] = useState({});

    const [entityValue, setEntityValue] = useState('');
    const [utmMedium, setUtmMedium] = useState('');
    const [singleClick, setSingleClick] = useState(false);

    const {
        setValue,
        reset,
    } = useForm();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const entityParam = searchParams.get('entity');
        const utm = searchParams.get('utm_medium');

        if (entityParam && utm) {
            setEntityValue(entityParam);
            setUtmMedium(utm);
        } else if (entityParam) {
            setEntityValue(entityParam)
            console.log('entity: ', entityParam);
        } else {
            setUtmMedium(utm);
        }

    }, [entityValue, utmMedium])

    useEffect(() => {
        GetCountries().then((result) => {
            setCountriesList(result);
        });
        // Parse the query parameters from the URL
        const searchParams = new URLSearchParams(window.location.search);
        // Get the value of the 'ref' parameter
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        // Set the value to the state
        if (refParam) {
            console.log('Reference Value is ', refParam);
            setRefValue(refParam);
        }
        if (ptrParam) {
            console.log('Ptr Value is ', ptrParam);
            setPtrValue(ptrParam);
        }

        clientService.authorization().then((res) => {
            const token = res.token;
            const decryptToken = decryptData(token);
            TokenService.setUser(decryptToken);
        }).catch((err) => {
            console.log('Error for auth is ', err);
            toast.error("Authorization error!");
        });
    }, []);

    const [formData, setFormData] = useState({
        step1: { agentId: refValue, parentId: ptrValue, firstName: '', email: '', phone: '', city: '', password: '', confirmPassword: '', country: '', nationality: '', website: '', proofOfIdentity: '', proofOfAddress: '', entity: entityValue, utmMedium: utmMedium },
    });

    const data = {
        agentId: refValue,
        firstName: formData.step1.firstName,
        email: formData.step1.email,
        phone: formData.step1.phone,
        country: formData.step1.country,
        nationality: formData.step1.nationality,
        city: formData.step1.city,
        password: formData.step1.password,
        website: formData.step1.website,
        parentId: ptrValue,
        entity: entityValue,
        utm_medium: utmMedium,
    }
    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, phone: false, accountType: false, country: false, nationality: false, password: false, confirmPassword: false, proofOfIdentity: false, proofOfAddress: false
        }
    });



    const formValueChange = (step, name, value) => {
        if (name === 'email') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                setEmailError(1); // Set email error state if the email is invalid
            } else {
                setEmailError(0); // Clear email error state if the email is valid
            }
        }

        // Update form data
        setFormData((prevFormData) => ({
            ...prevFormData,
            [step]: {
                ...prevFormData[step],
                [name]: value,
            },
        }));

        // Add password and confirm password validation logic here if needed
        if (name === 'password' || name === 'confirmPassword') {
            if (formData.step1.password === formData.step1.confirmPassword) {
                setValidationErrors(false);
            }
            validatePassword(formData.step1.password);
            validateConfirmPassword(formData.step1.confirmPassword);
        }
    };



    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{8,15}$/;
        const isValid = regex.test(password);

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                password: !isValid,
            },
        }));
    };

    const validateConfirmPassword = (confirmPassword) => {
        const { password } = formData.step1;
        const isValid = password === confirmPassword || (password.length === confirmPassword.length + 1);

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                confirmPassword: !isValid,
            },
        }));
    };

    const resetForm = () => {
        setFormData({
            step1: { agentId: '', firstName: '', lastName: '', email: '', phone: '', city: '', password: '', confirmPassword: '', country: '', nationality: '' },
        });
    }
    const isStep1Valid = () => {
        const { firstName, email, phone, password, confirmPassword, country, nationality, city, website, proofOfAddress, proofOfIdentity } = formData.step1;

        // Check each field individually and set their validity
        const isfirstNameValid = firstName.trim() !== '';
        //const islastNameValid = lastName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isphoneValid = phone.trim() !== '';
        const isPasswordValid = password.trim() !== '';
        const isConfirmPasswordValid = confirmPassword.trim() !== '' && password.trim() === confirmPassword.trim();
        const isCountryValid = country !== '';
        const isNationalityValid = nationality !== '';
        const isCityValid = city.trim() !== '';
        const isProofOfAddress = proofOfAddress !== '';
        const isProofOfIdentity = proofOfIdentity !== '';

        if ( proofOfIdentity !== ( null || "" )  && proofOfIdentity === proofOfAddress) {
            toast.error('Uploaded files could not same!');
            return false;
        } 

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isphoneValid,
                password: !isPasswordValid,
                confirmPassword: !isConfirmPasswordValid,
                country: !isCountryValid,
                nationality: !isNationalityValid,
                city: !isCityValid,
                proofOfAddress: !isProofOfAddress,
                proofOfIdentity: !isProofOfIdentity
            },
        }));

        // Return true if all fields are valid, false otherwise
        return isfirstNameValid && isEmailValid && isphoneValid &&
            isProofOfIdentity && isProofOfAddress && proofOfAddress !== proofOfIdentity && isPasswordValid && isConfirmPasswordValid && isCountryValid && isNationalityValid;
    };

    const submitForm = (e, action) => {
        e.preventDefault();
        setCheck(true);
        if (action === 'login') {
            window.location.href = "https://partner-login.godocm.com";
        } else if (!singleClick) {
            setSingleClick(true);
            setLoading(true);
            const step1Data = { ...formData.step1, agentId: refValue, parentId: ptrValue, entity: entityValue ? entityValue : '', utmMedium: utmMedium ? utmMedium : '', kycStatus: 'new', ibStatus: 'pending' };

            if (!isStep1Valid()) {
                setLoading(false);
                toast.error('Please Fill all Fields!');
                setSingleClick(false);
                console.log('Form data:', step1Data);
                return;
            }

            if (!declaration) {
                setLoading(false);
                setSingleClick(false);
                toast.error('Please accept Declaration!');
                return;
            }

            // Remove unnecessary fields
            delete step1Data.confirmPassword;
            delete step1Data.proofOfAddress;
            delete step1Data.proofOfIdentity;

            console.log('Form data:', step1Data);

            const token = TokenService.getUser();
            clientService.postPartner(step1Data, token)
                .then((res) => {
                    const formDataValue = new FormData();

                    imageList.forEach((item) => {
                        formDataValue.append(item.key, item.value[0]);
                    });

                    formDataValue.append('customerId', res.id);

                    return clientService.uploadDocuments(formDataValue)
                        .then((res2) => [res, res2]); // Pass both res and res2 to the next then block
                })
                .then(([res, res2]) => {
                    const authPromise = clientService.authorizationIB(res.id.toString(), formData.step1.password);
                    return Promise.all([authPromise, res.id]);
                })
                .then(([auth, customerId]) => {
                    const token = auth.token;
                    const redirectURL = `https://partner-login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

                    window.location.href = redirectURL;
                    toast.success("Successfully Registered!");
                    console.log("Added client documents successfully!");
                })
                .catch((err) => {
                    toast.error("Error adding client or documents!");
                    console.error(err);
                    setSingleClick(false);
                })
                .finally(() => setLoading(false));
        }
    };

    const updateListFunction = (key, files) => {
        // Check if the key already exists in the imageList
        const existingIndex = imageList.findIndex(entry => entry.key === key);

        if (existingIndex !== -1) {
            // If the key already exists, update its value
            const updatedList = [...imageList];
            updatedList[existingIndex].value = files;
            setImageList(updatedList);
        } else {
            // If the key does not exist, add it as a new entry
            const newValue = {
                key,
                value: files
            };
            const updatedList = [...imageList, newValue];
            setImageList(updatedList);
        }
    };
    const handlePhoneChange = (status, value, countryData, number, id) => {
        setSelectedCountryCode(countryData?.phone_code); // Update the selected country code
        // Other logic as needed
    };

    return (
        <div>
            {loading ?
                <div id="global-loader1">
                    <img src={loader} className="loader-img" alt="Loading...." />
                </div> : <>
                    <CustomForm style={{ backgroundImage: '../../background.jpg' }}>
                        <div className="justify mt_100">
                            {/*<InfoText />*/}
                            <Row>
                                <Col md={4} style={{ display: 'none' }}>
                                    <FormGroup>
                                        <Label>Ibid</Label>
                                        <div className="input-group">
                                            <Input className={"form-control"}
                                                name="agentId" type="text"
                                                required="" placeholder="John"
                                                value={refValue}
                                                onChange={(e) => formValueChange('step1', 'agentId', refValue)}
                                            />
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>Full Name ( as per your passport or national ID )</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (check && !formData.step1.firstName && 'is-invalid')}
                                                name="firstName" type="text"
                                                required="" placeholder="John"
                                                //invalid={validationErrors.step1.firstName}
                                                //valid={!validationErrors.step1.firstName}
                                                value={formData.step1.firstName}
                                                onChange={(e) => formValueChange('step1', 'firstName', e.target.value)}
                                            />
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col md={4} style={{ display: 'none' }}>
                                    <FormGroup>
                                        <Label>Last Name</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (check && !formData.step1.lastName && 'is-invalid')}
                                                type="text"
                                                name="lastName"
                                                value={formData.step1.lastName}
                                                //invalid={validationErrors.step1.lastName}
                                                //valid={!validationErrors.step1.lastName}
                                                required="" placeholder="Doe"
                                                onChange={(e) => formValueChange('step1', 'lastName', e.target.value)}
                                            />
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>Email Address</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (check && !formData.step1.email && 'is-invalid')}
                                                type="email"
                                                name="email"
                                                validation={{ required: true }}
                                                value={formData.step1.email}
                                                invalid={emailError === 1 && formData.step1.email !== '' && 1 || validationErrors.step1.email}
                                                valid={emailError === 0 && formData.step1.email !== '' && 1 || !validationErrors.step1.email}
                                                placeholder="john@gmail.com"
                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                            />
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>Phone No</Label>
                                        <div className="input-group">
                                            <IntlTelInput
                                                name="phone"
                                                containerClassName="intl-tel-input"
                                                inputClassName={"form-control " + (check && !formData.step1.phone && "is-invalid")}
                                                style={{ width: '100%' }}
                                                maxLength={15}
                                                format="true"
                                                defaultCountry={selectedCountryCode || 'ae'}
                                                useMobileFullscreenDropdown={false}
                                                value={formData.step1.phone} // Ensure the value is set correctly
                                                onSelectFlag={(selectedCountryData, fullNumber, isvalid, currentNumber) => {
                                                    console.log('Selected Country:', selectedCountryData, fullNumber, currentNumber);
                                                    setCountryid(fullNumber && fullNumber.iso2 && fullNumber.iso2.toUpperCase());
                                                }}
                                                onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                    console.log('Selected Country:', countryData?.name); // Log the selected country name
                                                    formValueChange('step1', 'phone', value); // Update form data with the phone number value
                                                    setSelectedCountryName(countryData?.name);
                                                }}
                                            />



                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row >
                                <Col md={12}>
                                    <Row>
                                        <Col md={8}>
                                            <Row>

                                                <Col md={6}>

                                                    <FormGroup>
                                                        <Label>Country of Residence</Label>
                                                        <Input
                                                            className={"mb-3 " + (check && !formData.step1.country && 'is-invalid')}
                                                            type="select"
                                                            name="country"
                                                            onChange={(e) => {
                                                                const selectedCountryName = e.target.value; // Get the selected country name
                                                                const selectedCountry = countryList.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                                                if (selectedCountry) {
                                                                    setCountryid(selectedCountry.iso2);
                                                                    setSelectedCountryName(selectedCountry?.name);
                                                                    setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                                                    console.log("Country Is ", selectedCountry);
                                                                    formValueChange('step1', 'country', selectedCountry.name); // Update form data with the selected country name
                                                                    GetState(selectedCountry.id).then((result) => {
                                                                        setStateList(result); // Update the state list based on the selected country
                                                                        // Reset the selected state and city
                                                                        setStateid('');
                                                                        setCityList([]);
                                                                    });
                                                                }
                                                            }}
                                                            //invalid={validationErrors.step1.country}
                                                            //valid={!validationErrors.step1.country}
                                                            value={countryList.find(country => country.iso2 === countryid)?.name} // Set the value to the selected country name
                                                        >
                                                            <option value="">-- Select --</option>
                                                            {countryList.map((item, index) => (
                                                                <option key={index} value={item.name}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </Input>


                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>

                                                    <FormGroup>
                                                        <Label>Nationality</Label>
                                                        <Input
                                                            className={"mb-3 " + (check && !formData.step1.nationality && 'is-invalid')}
                                                            type="select"
                                                            name="nationality"
                                                            onChange={(e) => {
                                                                const selectedCountryName = e.target.value; // Get the selected country name
                                                                const selectedCountry = countryList.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                                                if (selectedCountry) {
                                                                    formValueChange('step1', 'nationality', selectedCountry.name); // Update form data with the selected country name
                                                                }
                                                            }}
                                                            //invalid={validationErrors.step1.country}
                                                            //valid={!validationErrors.step1.country}
                                                            value={formData.step1.nationality} // Set the value to the selected country name
                                                        >
                                                            <option value="">-- Select --</option>
                                                            {countryList.map((item, index) => (
                                                                <option key={index} value={item.name}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </Input>


                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <Label>Address</Label>
                                                    <div className="input-group mb-3">
                                                        <Input
                                                            className={"form-control " + (check && !formData.step1.city && 'is-invalid')}
                                                            name="city"
                                                            type="text" required=""
                                                            value={formData.step1.city}
                                                            onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                            placeholder="Address" />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="password"
                                                            className={"form-control required input_password " + (check && !formData.step1.password && 'is-invalid')}// + (!validationErrors.step1.password || formData.step1.password ? 'is-valid' : 'is-invalid')}
                                                            placeholder="***********"
                                                            invalid={validationErrors.step1.password}
                                                            valid={!validationErrors.step1.password}
                                                            value={formData.step1.password}
                                                            onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Confirm Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="confirmPassword"
                                                            className={"form-control required input_password " + (check && !formData.step1.confirmPassword && 'is-invalid')}// + (!validationErrors.step1.confirmPassword || formData.step1.confirmPassword ? 'is-valid' : 'is-invalid')}
                                                            placeholder="***********"
                                                            invalid={validationErrors.step1.confirmPassword}
                                                            valid={!validationErrors.step1.confirmPassword}
                                                            value={formData.step1.confirmPassword}
                                                            onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md={12}>
                                                    <Label>Website</Label>
                                                    <div className="input-group mb-3">
                                                        <Input
                                                            className={"form-control"}
                                                            name="website"
                                                            type="text" required=""
                                                            value={formData.step1.website}
                                                            onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                            placeholder="Website" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <PasswordValidation />
                                            </FormGroup>
                                        </Col>


                                    </Row>
                                </Col>

                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Label for="proofOfIdentity">Proof of Identity (Passport or National ID)</Label>
                                    <Input
                                        className={"mb-3 " + (check && !formData.step1.proofOfIdentity && "is-invalid")}
                                        type="file"
                                        name="proofOfIdentity"
                                        onChange={(e) => {
                                            updateListFunction('PROOF_OF_ID', [e.target.files[0]]);
                                            formValueChange('step1', e.target.name, e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label for="proofOfAddress">Proof of Address (Utility Bill)</Label>
                                    <Input
                                        className={"mb-3 " + ((check && !formData.step1.proofOfAddress) && "is-invalid")}
                                        type="file"
                                        name="proofOfAddress"
                                        onChange={(e) => {
                                            updateListFunction('PROOF_OF_ADDRESS', [e.target.files[0]]);
                                            formValueChange('step1', e.target.name, e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="input-group mb-3">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="declaration"
                                                checked={declaration}
                                                onChange={() => setDeclaration(!declaration)}
                                            />
                                            &nbsp;&nbsp;&nbsp; I agree to the <a href="https://godopartner.com/legal/GoDo_Partner_Agreement.pdf">Terms and Conditions</a>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <div className="justify text_center mt_50">
                                <Button onClick={(e) => submitForm(e, 'login')} className='primary-bg-color' style={{ height: '50px' }}>
                                    {'Log In'}
                                </Button>

                                <Button type="submit" onClick={(e) => submitForm(e, 'submit')} className='primary-bg-color' style={{ height: '50px' }}>
                                    {'Register'}
                                </Button>
                            </div>
                        </div>
                    </CustomForm>
                </>}
        </div>
    )
}
